.bottomNav {
    background-color: #2e2d3c;
    padding-top:20px;
    padding-bottom:40px;
    display: flex;
    justify-content: space-around;
    position: fixed;
    left: 50%;
    bottom: 0;
    width: 100%;
    max-width: 10rem;
    transform: translateX(-50%);
    z-index: 2000;

    .navItem{
        text-align: center;
        font-size:20px;
        font-weight: bold;

        div{
            margin-top:6px;
        }

        img{
            width: 60px;
            height:60px;
        }

        .curText{
            color:#00C885
        }

        .text, .text:visited, .text:link{
            color:rgba(255,255,255,0.45);
            text-decoration: none;
        }

    }
}





