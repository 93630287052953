/* pages/player/component/title/title.wxss */
.mainTitle{
    font-size: 55px;
    font-weight: bold;
    margin-top: 40px;
    color: #dddddd;
    img{
        width:40px;
        height:40px;    
    }
    .iconText{
        margin-left:10px;
        cursor: pointer;
    }
}
.subTitle{
    font-size: 32px;
    color: #999;
    margin-top: 20px;
}
.desc{
    font-size: 24px;
    color: #999;
    
    width:500px;
    margin:0 auto;
    margin-top: 20px;
}

.nameDialog{
    :global {
        .t-dialog__title{
            font-size:28px;
        }

        input{
            margin-top:20px;
            height: 60px;
            text-align: center;
            font-size: 26px;
        }
    }
}