.container {
    padding:0 50px 200px 50px;

    .titleDesc {
        margin-top: 40px;
        font-size: 32px;
        color: #dddddd;
        display: flex;
        align-items: center;

        .iconBianji {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
    }

    
    .labels{
        display:flex;
        flex-wrap: wrap;
        margin-top:40px;
        
        .labelItem{
            text-align:center;
            padding: 0 28px;
            margin-top: 40px;
            cursor: pointer;

            .itemMain{
                width:160px;
                height:160px;   
                border-radius:50%;
                overflow: hidden;
                position: relative;

                .itemImg{
                    width:160px;
                    height:160px;   
                }
                .itemTick{
                    width:100%;
                    height:100%;
                    position:absolute;
                    top:0;
                    left:0;
                    background-color:rgba(0,0,0,0.5);

                    .tickImg{
                        width:50%;
                        height:50%;
                        margin-top:40px;
                    }
                }
            }
               
            .itemName{
                font-size:28px;  
                color: #cccccc;
                margin-top:10px;      
            }
        }    
    }

    
    .writeMusic {
        width: 400px;
        display: block !important;
        margin: 80px auto 0 auto;
        font-size:30px !important;
        height:80px !important;
        border-radius: 15px !important;
    }
}





