 
 .container {
    padding:0 40px 40px 40px;

    .title{
        font-size: 36px;
        color: #FFF8F8;
        font-weight: bold;
        text-align: center;
        margin-top:10px;
    }

    .desc{
        font-size: 26px;
        color: #FFF8F8;
        opacity: 0.45;
        text-align: center;
        margin-top:10px;
    }

    .step{
        width: 100%;
        display: flex;
        position: relative;
        margin-top: 30px;

        .stepLine{
            height:4px;
            width: 33.3%;
            background-color: #00C885;
            opacity: 0.1;
        }

        .stepRound{
            position: absolute;
            top:-8px;
            width: 20px;
            height:20px;
            background-color: #264F57;
            border-radius: 50%;
        }
        .round1{
            left:0px;
        }
        .round2{
            left:213px;
        }
        .round3{
            left:436px;
        }
        .round4{
            right:0px;
        }

        .deepColor{
            opacity: 1;
            background-color: #00C885;
        }
    }
    .main{
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        margin-top: 10px;
        // height: 900px;
        // overflow: auto;

        .item{
            width: 210px;
            //height:200px;
            margin-top:20px;
            position: relative;
            background-color: #2a2a34;
            border-radius:20px;
            overflow: hidden;
            border:2px solid #181822;
            text-align: center;
            cursor: pointer;

            .itemIcon{
                position: absolute;
                top:0px;
                left:0px;
                width:40px;
                height:40px;
            }

            image{
                width: 200px;
                height:200px;
                display: block;
            }

            .itemName{
                text-align: center;
                font-size:24px;
                color:#FFF8F8;
                background-color: #35343e;
                line-height: 45px;
                opacity: 0.9;
            }
        }
        .selected{
            border:2px solid #00C885;
        }
    }

    .btn{
        width: 670px;
         height: 80px;
         display: block;
         margin:80px auto 0 auto;
         font-size:28px;
    }
 }