.containerInfo {
    padding: 40px;

    .header {
        position: relative;

        .edit {
            width: 40px;
            height: 40px;
            background-color: #00C885;
            border-radius: 50%;
            top: 97px;
            left: 367px;
            position: absolute;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;

            .editImg {
                width: 80%;
                height: 80%;
            }
        }
    }

    .avatarWrapper {
        width: 140px;
        height: 140px;
        padding: 0px;
        margin-bottom: 100px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        position: relative;

        .file{
            width: 100%;
            height: 100%;
            position: absolute;
            top:0;
            left:0;
            opacity: 0;
        }
        .avatar {
            width: 140px;
            height: 140px;
        }
    }

    

    .Upload{
        :global {
            .t-upload__card{
                padding: 0;
            }
            .t-upload__card-content{
                width: 140px;
                height: 140px;
            }
            // .t-upload__card-delete-item {
            //     display: none;
            // }
        }
    }

    .nameWrapper {
        background-color: #eeeeee;
        border-top: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        padding: 20px;
        margin-bottom: 100px;
        display: flex;

        .name {
            margin-right: 20px;
            font-size:28px;
        }

        input {
            border: none;
            background-color: #eeeeee;
            font-size:28px;
        }
    }

    .writeMusic {
        width: 100%;
        display: block !important;
        margin: 80px auto 0 auto;
        font-size: 30px !important;
        height: 80px !important;
        border-radius: 15px !important;
    }

}