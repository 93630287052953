.container {
    padding:0 50px 150px 50px;

    .titleDesc {
        margin-top: 40px;
        font-size: 32px;
        color: #dddddd;
        display: flex;
        align-items: center;
    }

    .iconBianji {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .upload {
        margin-top: 40px;
        height: 575px;
        background-color: #3c3b4d;
        border-radius: 15px;
        overflow: hidden;
        position: relative;

        .addIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-left: -20px;
            margin-top: -20px;
            color: #626264;
        }

        .file {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        .video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 80;
        }

        .closeIcon{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 100;
            color: #dddddd;
        }
    }

    .Upload {
        margin-top: 40px;
        height: 575px;

        :global {

            .t-upload__card {
                padding: 0;
            }

            .t-upload__card-item {
                padding: 0;
                margin: 0;
                width: 100%;
                border-radius: 20px;
                overflow: hidden;
            }


            .t-upload__card-container,
            .t-upload__card-content {
                height: 575px;
                width: 100%;
                color: #666666;
                background-color: #3c3b4e;

                .t-icon-add {
                    color: #666666;
                    font-size: 40px;
                }
            }
        }


        .t-grid-item__content {
            height: 100%;

            .t-upload__wrapper {
                width: 100% !important;
                height: 100% !important;
            }
        }

        .t-icon-base {
            color: #666666;
        }
    }

    .rule {
        font-size: 26px;
        color: #aaaaaa;
        margin-top: 10px;
    }

    .writeMusic {
        width: 400px;
        display: block !important;
        margin: 80px auto 0 auto;
        font-size: 30px !important;
        height: 80px !important;
        border-radius: 15px !important;
    }
}