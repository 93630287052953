.container {
    margin: 40px 20px 0 20px;
    padding:0 0 200px 0;

    .list{
        margin-top:20px;
        display: flex;
        height: 346px;

        .lable{
            position: absolute;
            bottom:0;
            left:0;
            color:#dddddd;
            font-size: 20px;
            background-color: rgba(0,0,0,0.5);
            width: 100%;
            padding:5px 10px;
        }
    }
    .imgSquare{
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        image{
            width:100%;
            display: block;
        }
    }
    .imgSquare2{
        width:305px;
        height:169px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        image{
            width:100%;
            display: block;
        }
    }

    .imgRectangle{
        height:100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        image{
            height:100%;
        }
    }

    .width160{
        width:160px;
    }

    .width193{
        width:193px;
    }

    .width230{
        width:230px;
    }
    .width300{
        width:300px;
    }
    .height405{
        height:405px;
    }
    .height197{
        height:197px;
    }

    .marginR10{
        margin-right:10px;
    }
    .marginR16{
        margin-right:16px;
    }
    .marginB10{
        margin-bottom:10px;
    }

    .aspectFill{
        height: 100%;
        width: auto;
        object-fit: cover;
    }

    
    .writeMusic {
        width: 400px;
        display: block !important;
        margin: 80px auto 0 auto;
        font-size:30px !important;
        height:80px !important;
        border-radius: 15px !important;
    }

    .textDesc{
        font-size: 25px;
        color: #dddddd;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}





