/* pages/player/component/cover/cover.wxss */
.btnList {
    display: flex;
    justify-content: space-around;
    color: #cccccc;
    font-size: 24px;
    margin-top: 90px;
  }

  .btn {
    line-height: 50px;
    cursor: pointer;
  }
  .img{
    width: 50px;
    height: 50px;
  }