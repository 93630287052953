.containerMy {
    .header {
        padding: 0 30px 40px 30px;
        border-bottom: 1px solid #cccccc;
        display: flex;
        align-items: center;

        .info {

            margin-left: 30px;

            .merInfo {
                display: flex;

                .nickName {
                    color: #dddddd;
                    font-size: 36px;
                    position: relative;
                }

                .vip {
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: -10px;
                    left: 10px;
                    cursor: pointer;

                    .vipLogo {
                        color: #00C885;
                        font-size: 40px;
                        font-weight: bold;
                        font-style: italic;
                        margin-right: 20px;
                    }

                    .grey {
                        color: #999999;
                    }

                    .vipName {
                        background-color: #FFD700;
                        border-radius: 30px;
                        font-size: 20px;
                        padding: 5px 10px;
                    }
                }
            }

        }

        .expiredTime {
            font-size: 26px;
            color: #979797;
        }

        .avatar {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .historyList {
        padding: 40px 40px 180px 40px;

        .tabs {
            color: rgba(255, 255, 255, 0.9);
            display: flex;
            justify-content: space-around;
            font-size: 28px;
            margin-bottom: 20px;
            font-weight: bold;


            span {
                position: relative;
                padding-bottom: 20px;
                cursor: pointer;
            }

            .cur {
                color: #00C885;
            }

            .cur::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 30px;
                height: 3px;
                margin-left: -15px;
                background-color: #00C885;

            }
        }

        .title {
            color: #dddddd;
            font-weight: bold;
            margin-bottom: 25px;
            font-size: 32px;
        }

        .loading {
            text-align: center;
            color: #999999;
            font-size: 28px;
        }

        :global {

            .t-cell {
                padding: 20px 0;
                background-color: #181823;
            }

            .t-cell__left-icon {
                border-radius: 10px;

                img {
                    width: 130px;
                    height: 130px;
                    border-radius: 10px;
                }
            }

            .t-cell__title {
                margin-left: 20px;
                color: #dddddd;
                font-size: 28px;
                overflow: hidden; //超出一行文字自动隐藏
                text-overflow: ellipsis; //文字隐藏后添加省略号
                white-space: nowrap; //强制不换行
                max-width: 500px;
                display: inline-block;
            }

            .t-cell__description {
                color: #999999;
                font-size: 24px;
                margin-top: 30px;
            }

            .t-cell--bordered:after {
                border: none;
            }
        }


        .list {
            margin-top: 20px;

            .listItem {
                display: flex;
                margin-bottom: 40px;


                .left {
                    width: 130px;
                    height: 195px;
                    overflow: hidden;
                    border-radius: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .right {
                    margin-left: 30px;

                    .listTitle {
                        color: rgba(255, 255, 255, 0.9);
                        font-size: 28px;
                        margin-top: 40px;
                    }

                    .listTime {
                        color: #999999;
                        font-weight: 400;
                        font-size: 24px;
                        margin-top: 30px;
                    }
                }
            }
        }

    }
}