* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Lato", sans-serif;
  height: 100%;
}

.container {
  text-align: center;
  overflow: hidden;
  
}

.videoTag{
  width: 100%;
}