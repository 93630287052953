
.Header{
    color: #ffffff;
    padding:0 20px;
    height: 80px;
    font-size: 26px;
    line-height: 80px;
    position: relative;
    display: flex;
    justify-content: center;

    img{
        width: 40px;
        height: 40px;
        position: absolute;
        left:20px;
        top:50%;
        margin-top:-20px;
        cursor: pointer;

    }
}