.container {
    padding:0 50px 200px 50px;

    .titleDesc {
        margin-top: 40px;
        font-size: 32px;
        color: #dddddd;
        display: flex;
        align-items: center;
    }

    .iconBianji {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .Upload {
        margin-top: 40px;
        height: 575px;
    
        :global {

            .t-upload__card {
                padding: 0;
            }
    
            .t-upload__card-item {
                padding: 0;
                margin: 0;
                width: 100%;
                border-radius: 20px;
                overflow: hidden;
            }

    
            .t-upload__card-container,.t-upload__card-content{
                height: 575px;
                width: 100%;
                color: #666666;
                background-color: #3c3b4e;
    
                .t-icon-add {
                    color: #666666;
                    font-size: 40px;
                }
            }
        }
    
    
        .t-grid-item__content {
            height: 100%;
    
            .t-upload__wrapper {
                width: 100% !important;
                height: 100% !important;
            }
        }
    
        .t-icon-base {
            color: #666666;
        }
    }
    
    .writeMusic {
        width: 400px;
        display: block !important;
        margin: 80px auto 0 auto;
        font-size:30px !important;
        height:80px !important;
        border-radius: 15px !important;
    }
}





