/* pages/player/component/cover/cover.wxss */
.map{
    width: 550px;
    margin: 90px auto 0 auto;
    position: relative;

    .musicRotate{
        animation: rotate 5s linear infinite;
        width: 530px;
        height: 530px;
        border-radius: 20px;
        border-radius: 50%;
        overflow: hidden;
        border: 10px solid #000;
        box-sizing: border-box;
        display:flex;                    //flex布局
        justify-content: center;         //水平轴线居中
        align-items:center; 

        .img{
            height: 530px;
        }
    }

    @keyframes rotate{
        from{
        transform: rotate(0deg)
        }
        to{
        transform: rotate(359deg)
        }
    }

    .musicPaused{
        animation-play-state: paused;
    }
}

.playbox{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -70px;
    margin-top: -70px;
    cursor: pointer;
    
    .pre,.next{
        width: 32px;
        height: 32px;
        vertical-align: middle;
    }
    .play{
        width: 140px;
        height: 140px;
        vertical-align: middle;
    }
}