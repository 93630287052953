
.containerPay {
    color: #ffffff;
    ::-webkit-scrollbar {  
        width: 8px;  
        height: 8px;
        background-color: #181822;  
      }
      ::-webkit-scrollbar-thumb {
        background-color: #35343e;  
        border-radius: 5px;
      }

    .head {
        height: 184px;
        color: #000000;
        padding:0 30px;
        font-size:40px;
        margin: 80px 40px 0px 40px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        overflow: hidden;
        
        .img {
            width: 104px;
            height: 104px;
            overflow: hidden;
            border-radius: 50%;
            border:1rpx solid #ffffff;

            image{
                width: 100%;
                height: 100%;
                display: flex;
            }
        }

        .right{
            margin-left:20px;

            .state{
                font-size: 32px;
            }

            .desc{
                font-size: 24px;
                color: #000000;
                opacity: 0.65;
                margin-top:5px;
            }

        }
    }

    .bg1{
        background: url('https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/pay/bg1.png');
        background-size: cover;
    }
    .bg2{
        background: url('https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/pay/bg2.png');
        background-size: cover;
    }

    .title{
        margin-top:50px;
        margin-left:20px;
        font-weight: bold;
        font-size: 36px;
    }

    .price {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-top:30px;
        padding-left:40px;
        padding-bottom:25px;
        overflow: auto;



        .priceItem {
            flex-shrink:0;
            text-align: center;
            border:2px solid #181822;
            border-radius: 12px;
            width: 240px;
            height: 220px;
            position: relative;
            //overflow: hidden;
            box-sizing: border-box;
            margin-right:20px;
            overflow: hidden;
            background-color: #2a2a34;
            cursor: pointer;

            .priceHead{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height:48px;
                background-color: #35343e;
                //border-radius: 20rpx;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                
            }

            .month {
                font-size: 24px;
                opacity: 0.65;
                line-height: 48px;
            }

            .pricePay {
                font-size: 60px;
                font-weight: bold;
                position: relative;
                display: inline-block;
                margin-top: 30px;
                color: #00C885;
            }
            .symbol{
                font-size: 20px;
                position: absolute;
                left: -24px;
                top: 38px;
            }

            .marketPrice {
                font-size: 22px;
                color: #979797;
                text-decoration: line-through;
                margin-bottom: 30px;
            }
        }

        

        .cur{
            border:2px solid #00C885;
        }
    }

    .pay {
        width: 670px !important;
        height:88px !important;
        display: block;
        margin: 50px auto 0 auto;
        color: #ffffff;
        border-radius: 20px;
        font-size: 30px !important;
        font-weight: bold !important;
        background-color: #00C885 !important;
    }

    .notes {
        font-size: 24px;
        color: #FFF8F8;
        margin: 20px auto 0 auto;
        opacity: 0.45;
        text-align: center;

    }

    // .equityList{
    //     margin-top:20px;  
    //     display: flex;
    //     justify-content: space-between;
    //     padding:0 20px;
        
    //     .equityItem{
    //       border:4px solid #000000;  
    //       width: 220px;
    //       height: 220px;
    //       position: relative;
    //       border-radius: 10px;
    //       overflow: hidden;
    //       text-align: center;

    //       .text{
    //         font-size:28px;  
    //         margin-top:20px;
    //         display:block;
    //         color:#e2b142;
    //         font-weight: bold;
    //       }
    //       .text1{
    //         font-size:25px;  
    //         margin-top:20px;
    //         display:block;
    //       }
    //       .text2{
    //         font-size:25px;  
    //         color:#979797;
    //       }

    //       .num{
    //         font-size:150px;
    //         font-weight: bold;
    //         color: #00C885;
    //         position: absolute;
    //         bottom: 0;
    //         right: -5px;
    //         line-height: 95px;
    //         opacity: 0.3;
            
    //       }
    //     }
    //   }
    .popMain{
        background-color: #ffffff;
        padding:40px;
        font-size:22px;
        width: 400px;
        text-align: center;

        .popText{
            color: #000000;
        }

        .popCode{
            margin-top:10px;
        }

        .popBtns{
            margin-top:10px;
            display: flex;
            justify-content: space-around;

            .popCancel{
                background-color: #cccccc;
                color:#ffffff;
                padding:15px;
                border-radius: 10px;
            }

            .popConfirm{
                background-color: #00C885;
                color:#ffffff;
                padding:15px;
                border-radius: 10px;
            }
        }
    }
    .equityList{
        margin-top:30px;  
        padding:0 40px;

        .equityItem{
          width: 100%;
          position: relative;
          overflow: hidden;
          display: flex;
          margin-bottom: 40px;
          align-items: center;
          .img{
            width: 104px;
            height: 104px;
            margin-right: 20px;
          }
          .content{
            flex:1;
            text-align: left;
          }
          .text{
            font-size:28px;  
            display:block;
            color:#fff;
            font-weight: bold;
          }
          .text1{
            font-size:24px;  
            margin-top:10px;
            display:block;
            color: #FFF8F8;
            opacity: 0.45;
          }
          .text2{
            font-size:25px;  
            color:#979797;
          }

          .num{
            font-size:150px;
            font-weight: bold;
            color: #00C885;
            position: absolute;
            bottom: 0;
            right: -5px;
            line-height: 95px;
            opacity: 0.3;
          }
        }
      }

    .gameDialog{
        :global {
            .t-dialog__title{
                font-size:28px;
            }

            input{
                margin-top:20px;
                height: 60px;
                text-align: center;
                font-size: 26px;
            }
        }
        
    }

}