.recordMusic {
    padding:0 50px 150px 50px;
    .titleDesc{
        margin-top: 40px;
        font-size: 32px;
        color: #dddddd;
        display: flex;
        align-items: center;
        .iconBianji{
            width: 40px;
            height:40px;    
            margin-right:10px;
        }
    }
    .block{
      background-color: #3c3b4e;
      border-radius: 20px;
      margin-top: 40px;
      height: 350px;

      .chart {
        width: 100%;
        height: 100%!important;
      }
    
    }
    .rule{
      font-size: 26px;
      color: #aaaaaa;
      margin-top: 10px;
    }
    .reckonTime{
      font-size:60px;
      color:#dddddd;
      text-align: center;
      margin-top:60px;
    }
    .btns{
      color:#dddddd;
      display: flex;
      justify-content: space-around;
      margin-top:70px;
      height:220px;  
      user-select:none;
      
      .btnItem{
        text-align: center;
      }
      .btnText{
        margin-top:20px;
        text-align: center;
        font-size:26px;
      }
      img{
        width:140px;
        height:140px;
      }
    }
  }
  
  .btn {
    width: 200px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #1AAD19;
    color: white;
    margin-bottom: 20px;
    border-radius: 5px;
  }