* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html, body {
  font-family: "Lato", sans-serif;
  height:  100%;
}

.wrap {
  height: 100%;
}

.tabs{
  z-index: 10;
  position: sticky;
  top: 0;
  color:#dddddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size:28px;
  height: 96px;
  background-color: #181823;
}

.tabsLabel{
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.tabsLabel img{
  width:35px;
  height:35px;    
}
.cur{
  color:#00C885;
}

.cur::after{
  content: "";
  background:#00C885;
  width: 36px;
  height: 6px;
  position: absolute;
  left: 50%;
  bottom: -22px;
  margin-left:-18px;
}

.header {
  position: relative;
}

.header img {
  height: 100%;
  width: 90%;
  object-fit: contain;
}

.footer_wap_footer {
  width: 100%;
  background-color: var(--bg-page-grey);
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100px;
}

.footer_wap_footer_grid, .footer_wap_footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer_wap_footer a {
  color: #555;
  text-decoration: none;
}

.footer_wap_footer_grid {
  font-size: 12px;
  line-height: .14rem;
}

.footer_wap_footer_grid, .footer_wap_footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer_wap_footer_copyright {
  margin-top: 16px;
  font-style: normal;
  font-size: 12px;
  line-height: .16rem;
  color: var(--t3-new);
  color: #555;
}

.footer_wap_footer_grid div {
  color: #555;
  padding: 0 10px;
  border-right: 1px solid var(--line-inside);
}

.header {
  display: flex;
  justify-content: center;
}

.popup_center {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingCard{
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}