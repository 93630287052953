.containerText {
    padding: 0 50px 230px 50px;

    .titleDesc {
        margin-top: 40px;
        font-size: 32px;
        color: #dddddd;
        display: flex;
        align-items: center;
    }

    .iconBianji {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    :global {
        .t-textarea{
            background-color:#3c3b4e;    
            border-radius: 20px;
            margin-top:40px;
            height:250px;
            padding:20px;

            textarea{
                color:#ffffff;
                font-size:28px;
            }
            textarea::-webkit-input-placeholder{
                color:#cccccc;
                font-size:28px;
              }
        }
    }

    .textCount{
        color: #dddddd;
        text-align: right;
        font-size:26px;
        margin-top:20px;
        margin-bottom:30px;
    }

    .lableTitle{
        color:#cccccc;
        margin-bottom:30px;
        font-size:28px;
    }

    .lableText{
        background-color:#3c3b4e;
        padding:8px 20px;
        margin-bottom:15px;
        border-radius: 10px;
        color:#dddddd;  
        font-size:28px;
        cursor: pointer;
    }
    .more{
        color:#dddddd;  
        font-size:28px;
        text-align: center;
        margin-top:40px;
        cursor: pointer;
    }

    
    .writeMusic {
        width: 400px;
        display: block !important;
        margin: 80px auto 0 auto;
        font-size:30px !important;
        height:80px !important;
        border-radius: 15px !important;
    }
}





