.container {
    padding: 0 40px;

    


    .title {
        font-size: 36px;
        color: #FFF8F8;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
    }

    .desc {
        font-size: 26px;
        color: #FFF8F8;
        opacity: 0.45;
        text-align: center;
        margin-top: 10px;

        image {
            width: 30px;
            height: 30px;
            position: relative;
            top: 5px;
        }
    }

    .step {
        width: 100%;
        display: flex;
        position: relative;
        margin-top: 30px;

        .stepLine {
            height: 4px;
            width: 33.3%;
            background-color: #00C885;
            opacity: 0.1;
        }

        .stepRound {
            position: absolute;
            top: -8px;
            width: 20px;
            height: 20px;
            background-color: #264F57;
            border-radius: 50%;
        }

        .round1 {
            left: 0px;
        }

        .round2 {
            left: 213px;
        }

        .round3 {
            left: 436px;
        }

        .round4 {
            right: 0px;
        }

        .deepColor {
            opacity: 1;
            background-color: #00C885;
        }
    }

    .main {
        //background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/main-bg.png);
        background-color: #2a2a34;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 1000px;
        border-radius: 60px;
        margin-top: 35px;
        position: relative;
        overflow: hidden;

        .playerPlay {
            width: 96px;
            height: 96px;
            margin: 600px auto 0 auto;
            display: block;
        }

        .begin,
        .end {
            color: #ffffff !important;
        }


        .heart {
            margin-left: 15px;
            text-align: center;
            color: #cccccc;
            position: absolute;
            bottom: 40px;
            right: 20px;
            cursor: pointer;


            image {
                width: 70px;
                height: 70px;
            }

            .num {
                width: 100%;
                font-size: 28px;
                color: #FFFFFF;
                opacity: 0.9;
                text-align: center;
            }
        }

    }

    .remind {
        color: rgba(255, 255, 255, 0.45);
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        cursor: pointer;

        img {
            width: 28px;
            height: 28px;
            margin-right: 6px;
        }
    }

    .btns {
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .btn {
            width: 320px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            cursor: pointer;

            img {
                width: 32px;
                height: 32px;
                margin-right: 8px;
            }
        }

        .share {
            background-color: #00C885;
            color: #FFF8F8;
        }

        .download {
            background-color: #2f2f38;
            color: #00C885;
        }

        .tip {
            background: linear-gradient(to bottom, #50a877, #397dc1);
            color: #FFF8F8;
            padding: 30px;
            position: relative;
            margin-top: 30px;
            border-radius: 20px;
            position: absolute;
            left: 0;
            top: -223px;
            width: 428px;
            font-size: 26px;
            line-height: 50px;
            overflow: hidden;

            .tipBg {
                width: 124px;
                height: 124px;
                position: absolute;
                bottom: -34px;
                right: -11px;
            }

            .iconClose {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 10px;
                right: 5px;

                image {
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }

    .btns::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -40px;
        left: 45px;
        border-top: 20px solid #397dc1;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        border-bottom: 20px solid transparent;
    }

    .hideTip::after {
        display: none;
    }

    .renew {
        text-align: center;
        color: rgba(255, 255, 255, 0.45);
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 30px;
            height: 30px;
        }

        span {
            color: #00C885;
        }
    }

    .popupShare {
        width: 100%;
        height: 1250px;
        background-color: #F2F3F2;
        border-radius: 10px;
        position: relative;
        box-sizing: border-box;
        text-align: center;

        .iconClose {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 40px;
            height: 40px;
        }

        .shareMain {
            width: 549px;
            height: 996px;
            margin-top: 68px;
        }

        .shareList {
            width: 530px;
            display: flex;
            justify-content: space-around;
            margin: 0 auto;
            margin-top: 20px;

            .shareItem {
                color: rgba(0, 0, 0, 0.65);
                font-size: 22px;
                background-color: #F2F3F2;
                margin: 0;
                padding: 0;
                border: 1px solid #F2F3F2;
                line-height: 35px;
                cursor: pointer;

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .shareItem::after {
                border: none; //黑色边框去掉了
            }
        }

    }

    .popupBlock {
        width: 504px;
        height: 540px;
        background: linear-gradient(to bottom, #C3FFEB 5%, #ffffff 15%);
        border-radius: 10px;
        position: relative;
        padding: 40px;
        box-sizing: border-box;

        .popupImage {
            width: 140px;
            height: 140px;
            position: absolute;
            top: -70px;
            left: 50%;
            margin-left: -70px;
        }

        .popupTitle {
            text-align: center;
            font-size: 32px;
            color: #000000;
            margin-top: 40px;
        }

        .popupList {
            margin-top: 20px;

            .popupItem {
                background-color: #F2F3F5;
                border-radius: 10px;
                margin-top: 10px;
                color: #050505;
                height: 72px;
                font-size: 28px;
                display: flex;
                align-items: center;

                .iconGou {
                    width: 28px;
                    height: 28px;
                    margin-left: 20px;
                }

                .iconDownload {
                    width: 28px;
                    height: 28px;
                    margin-left: 120px;
                    margin-right: 10px;
                }

            }
        }

        .popupBtn {
            width: 100%;
            height: 72px;
            font-size: 28px;
            margin-top: 110px;
            cursor: pointer;
        }
    }

    .t-button--primary::after {
        border: none;
    }

    .t-button__content {
        font-size: 32px;
        font-weight: none;
        font-weight: 400;
    }

    .t-dialog {
        background-color: #ffffff !important;
    }

    .t-input {
        background-color: #efefef;
        margin-top: 30px;
        border-radius: 20px;
    }
}