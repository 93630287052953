.CustomLoading {
    :global {
        .t-overlay{
            background-color: rgba(0, 0, 0, 0) !important;
        }
        .t-loading{
            background-color: rgba(0,0,0,0.8);
            padding:50px 70px;
            border-radius: 10px;
        }
        .t-loading__text{
            color: #ffffff;
            margin-top:20px !important;
        }
    }
    

}





