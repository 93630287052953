.Header {
    color: #ffffff;
    padding: 0 20px;
    height: 80px;
    font-size: 26px;
    line-height: 80px;
    position: relative;
    display: flex;
    justify-content: center;

    img {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -20px;
        cursor: pointer;

    }
}

.title {
    font-size: 36px;
    color: #FFF8F8;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;

    img{
        width: 30px;
        height: 30px;
        margin:0 5px;
        cursor: pointer;
    }
}

.desc {
    font-size: 26px;
    color: #FFF8F8;
    opacity: 0.45;
    text-align: center;
    margin-top: 10px;

    img{
        width: 30px;
        height: 30px;
        position: relative;
        top:5px;
        margin:0 5px;
        cursor: pointer;
    }

    span{
        text-decoration:underline;
        cursor: pointer;
    }
}

.step {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 30px;

    .stepLine {
        height: 4px;
        width: 33.3%;
        background-color: #00C885;
        opacity: 0.1;
    }

    .stepRound {
        position: absolute;
        top: -8px;
        width: 20px;
        height: 20px;
        background-color: #264F57;
        border-radius: 50%;
    }

    .round1 {
        left: 0px;
    }

    .round2 {
        left: 213px;
    }

    .round3 {
        left: 436px;
    }

    .round4 {
        right: 0px;
    }

    .deepColor {
        opacity: 1;
        background-color: #00C885;
    }
}

.t-button--primary::after{
    border: none;
}
.t-button__content{
    font-size: 32px;
    font-weight: none;
    font-weight: 400;
}  
.t-dialog{
    background-color:#ffffff !important;
}


.nameDialog{
    :global {
        .t-dialog__title{
            font-size:28px;
        }

        input{
            margin-top:20px;
            height: 60px;
            text-align: center;
            font-size: 26px;
        }
    }
}