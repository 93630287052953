.container {
    padding: 0 40px;

    .title {
        font-size: 36px;
        color: #FFF8F8;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
    }

    .desc {
        font-size: 26px;
        color: #FFF8F8;
        opacity: 0.45;
        text-align: center;
        margin-top: 10px;
    }

    .step {
        width: 100%;
        display: flex;
        position: relative;
        margin-top: 30px;

        .stepLine {
            height: 4px;
            width: 33.3%;
            background-color: #00C885;
            opacity: 0.1;
        }

        .stepRound {
            position: absolute;
            top: -8px;
            width: 20px;
            height: 20px;
            background-color: #264F57;
            border-radius: 50%;
        }

        .round1 {
            left: 0px;
        }

        .round2 {
            left: 213px;
        }

        .round3 {
            left: 436px;
        }

        .round4 {
            right: 0px;
        }

        .deepColor {
            opacity: 1;
            background-color: #00C885;
        }
    }

    .countdown {
        color: rgba(255, 255, 255, 0.9);
        text-align: center;
        font-size: 64px;
        margin-top: 50px;

        .desc1 {
            font-size: 26px;
            text-align: center;
            color: #FFF8F8;
            opacity: 0.45;
        }
    }

    .block {
        background-color: #181823;
        border-radius: 20px;
        margin: 30px auto 0 auto;
        height: 416px;
        width: 100%;
        overflow: hidden;
        position: relative;

        .musicPlay{
            position: absolute;
            left:-9999;
            top:-9999;
            z-index: -99;
            opacity: 0;
        }

        .play {
            width: 42px;
            height: 56px;
            position: absolute;
            left: 50%;
            top: 45%;
            margin-top: -28px;
            margin-left: -21px;
        }

        .blockSelect {
            position: absolute;
            left: 20px;
            top: 20px;
            display: flex;
            align-items: center;
            color: #FFF8F8;
            opacity: 0.9;
            font-size: 32px;

            .gou {
                width: 40px;
                height: 40px;
                margin-right: 15px;
            }
        }


        .blockExample {
            position: absolute;
            right: 20px;
            top: 20px;
            color: #FFF8F8;
            font-size: 24px;
            display: flex;

            .exampleItem {
                margin-left: 15px;
                display: flex;
                align-items: center;
            }

            .iconColor1 {
                border-radius: 50%;
                background-color: #00C885;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 8px;
            }

            .iconColor2 {
                border-radius: 50%;
                background-color: #000000;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 8px;
            }
        }

        .symbols {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 310px auto 0 auto;
        }

        .symbol {
            display: grid;
            color: rgba(255, 248, 248, 0.45);
            grid-template-columns: repeat(7, 14%);
            grid-column-gap: 4px;
            text-align: center;
            font-size: 20px;
            width: 92%;

            position: absolute;

            .symbolList {
                display: grid;
                grid-row-gap: 4px;

                .symbolItem {
                    width: 100%;
                    height: 8px;
                    background-color: #00C885;
                    opacity: 0;
                    border-radius: 2px;
                }

                .bright {
                    opacity: 1;
                }

            }
        }

        .symbol2 {
            .symbolItem {
                background-color: #000000 !important;
            }

            .bright {
                opacity: 0.5 !important;
            }

            .charts{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0;
                z-index: 10;
            }
        }
    }

    .bg1 {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/bg1.png);
        background-size: cover;
    }

    .bg2 {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/bg2.png);
        background-size: cover;
    }

    .btn {
        width: 670px;
        height: 80px;
        display: block;
        margin: 80px auto 0 auto;
        font-size: 28px;
    }
}