.container {
    padding: 0 40px;
  }
  
  .title {
    font-size: 36px;
    color: #FFF8F8;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .desc {
    font-size: 26px;
    color: #FFF8F8;
    opacity: 0.45;
    text-align: center;
    margin-top: 10px;
  }
  
  .step {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 30px;
  
    .stepLine {
      height: 4px;
      width: 33.3%;
      background-color: #00C885;
      opacity: 0.1;
    }
  
    .stepRound {
      position: absolute;
      top: -8px;
      width: 20px;
      height: 20px;
      background-color: #264F57;
      border-radius: 50%;
    }
  
    .round1 {
      left: 0px;
    }
  
    .round2 {
      left: 213px;
    }
  
    .round3 {
      left: 436px;
    }
  
    .round4 {
      right: 0px;
    }
  
    .deepColor {
      opacity: 1;
      background-color: #00C885;
    }
  }
  
  .warp {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
    .rect {
      width: 672px;
      height: 840px;
      margin: auto;
  
      .rectcenter {
        height: 554px;
        text-align: center;
  
        .rtMoney {
          font-size: 65px;
          font-weight: 800;
          padding: 80px 0;
          color: rgba(238, 195, 24, 1);
        }
  
        .rtMoney1 {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(238, 195, 24, 1);
          padding: 20px 0;
        }
      }
  
      .btnredbg {
        width: 370px;
        height: 80px;
        background: rgba(255, 211, 125, 1);
        border-radius: 40px;
        line-height: 80px;
        margin: auto;
        color: #F42335;
        font-size: 32px;
        text-align: center;
      }
  
      .iconfil {
        width: 77px;
        margin: 60px auto;
      }
    }
  }
  
  
  .rainBox {
    margin: 0;
    padding: 0;
    position: relative;
    height: 856px;
    overflow: hidden;
    background-size: 100%;
    margin-top: 40px;
    border-radius: 20px;
    background-color: #2a2a34;
    background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step1/rain-bg.png);
    background-size: cover;
  
    .winnum {
      display: inline-block;
      color: #000;
      font-size: 40px;
      margin-top: 30px;
    }
  
    .guide {
      text-align: center;
  
      &Img {
        width: 300px;
        height: 380px;
        margin-top: 120px;
      }
  
      &Title {
        color: #FFF8F8;
        font-size: 28px;
        opacity: 0.9;
        margin-bottom: 10px;
      }
  
      &Desc {
        color: #FFF8F8;
        font-size: 24px;
        opacity: 0.45;
        line-height: 40px;
      }
    }
  }
  
  .t-dialog {
    background-color: #ffffff !important;
  }
  
  .countDown {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    // background: url(https://img.51fanbei.com/h5/app/activity/redRain_02.png) no-repeat center;
    // background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .number {
      font-size: 200px;
      font-weight: bold;
      position: absolute;
      opacity: 0;
      color: #FFF8F8;
      // transform: translate(-50%,-50%);
      // -webkit-transform: translate(-50%, -50%);
    }
  
    @keyframes countdown {
      0% {
        // transform: translate(-50%, -50%);
        // -webkit-transform: translate(-50%, -50%);
        opacity: 1;
        font-size:200px;
      }
  
      100% {
        // transform: scale(1) translate(-50%, -50%);
        // -webkit-transform: translate(-50%, -50%);
        opacity: 0;
        font-size:400px;
      }
    }
  
    .number:nth-child(1) {
      animation: countdown 1s linear 0s forwards;
    }
  
    .number:nth-child(2) {
      animation: countdown 1s linear 1s forwards;
    }
  
    .number:nth-child(3) {
      animation: countdown 1s linear 2s forwards;
    }
  
  
    .second {
      width: 340px;
      height: 394px;
      margin: 410px auto;
      display: block;
      // margin-top: 205px;
    }
  }
  
  .redPacket {
    position: relative;

    span {
      width: 250px;
      height: 250px;
      display: block;
  
  
      &.do {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/do.png) no-repeat center;
        background-size: 100% 100%;
      }
  
      &.mi {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/mi.png) no-repeat center;
        background-size: 100% 100%;
      }
  
      &.re {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/re.png) no-repeat center;
        background-size: 100% 100%;
      }
  
      &.fa {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/fa.png) no-repeat center;
        background-size: 100% 100%;
      }
  
      &.sol {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/soi.png) no-repeat center;
        background-size: 100% 100%;
      }
  
      &.la {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/la.png) no-repeat center;
        background-size: 100% 100%;
      }
  
      &.si {
        background: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/si.png) no-repeat center;
        background-size: 100% 100%;
      }
    }
  
    .package1 {
      display: none;
    }
  
    .package {
      position: absolute;
      top: -300px;
      z-index: 3;
      animation: aim_move 5s linear 0s forwards;
    }
  
    .animationPlayState{
      animation-play-state:paused
    }
  
    a {
      display: block;
    }
  
  
  }
  
  .time {
    color: #FFFFFF;
    font-size: 64px;
    text-align: center;
    margin-top: 20px;
  }
  
  .symbol{
    display: grid;
    color:rgba(255, 248, 248,0.45);
    grid-template-columns: repeat(7, 14%);
    grid-column-gap: 2px;
    text-align: center;
    font-size: 20px;
    margin-top:20px;
  
    .symbolList{
      display: grid;
      grid-row-gap: 2px;
  
      .symbolItem{
        width: 100%;
        height:8px;
        background-color: #00C885;
        opacity: 0.1;
      }
  
      .bright{
        opacity: 1;
      }
  
    }
  }
  
  .fade-out {
    animation: fadeOut 10s forwards !important;
    animation-fill-mode: forwards !important;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  
  @keyframes aim_move {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(120vh);
    }
  }