.musicPlay{
    position: absolute;
    top:-9999px;
    left:-9999px;
 }
 
 .container {
    padding:0 0 200px 0;
 
     .player{
         width: 100%;
         height: 830px;
         background-image: url(https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/main-bg1.png);
         background-size: cover;
         overflow: hidden;
 
         .title{
             font-size:36px;
             color:#ffffff;
             margin-top:40px;
             margin-left:30px;
         }
 
         .playerMain{
             margin-top:480px;
             text-align: center;
             
             
 
             .playerOperate{
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-top:20px;
 
                 .opacity{
                     opacity: 0.4;
                 }
 
                 .playerPrev{
                     width: 44px;
                     height:32px;
                     margin-right: 60px;
                 }
 
                 .playerNext{
                     width: 44px;
                     height:32px;
                     margin-left: 60px;
                 }
 
                 .playerPlay{
                     width: 44px;
                     height: 60px;
                     position: relative;
                     top:5px;
                     opacity: 0.65;
                     cursor: pointer;
                 }
                 .playerPlay2{
                     opacity: 1;
                 }
             }
 
             .playerTitle{
                 font-size:28px;
                 color: #ffffff;
             }
             .playerDesc{
                 font-size:24px;
                 color: #ffffff;
                 opacity: 0.45;
                 margin-top:5px;
             }
 
             
         }
     }
 
     .exampleTitle{
         text-align: center;
         margin:60px auto 50px auto;
         border-top:1px solid rgba(255,255,255,0.3);
         position: relative;
         width: 600px;
 
         .exampleText{
             width: 360px;
             color:rgba(255, 248, 248,0.65);
             line-height: 40px;
             font-size:24px;
             background-color: #181822;
             position: absolute;
             top:-20px;
             left:50%;
             margin-left:-190px;
 
         }
     }
 
     .steps{
         display: flex;
         justify-content: space-between;
         padding:0 30px;
 
         .stepsItem{
             width: 160px;
             text-align: center;
 
             .stepsImg{
                 width: 160px;
                 height:160px;
             }
             .stepsNum{
                 width: 82px;
                 height: 36px;
                 margin-top: -20px;
                 position: relative;
                 top: -25px;
             }
             .stepsText{
                 color:rgba(255, 255, 255,0.65);
                 font-size:24px;
                 position: relative;
                 top: -20px;
             }
         }
     }
     .btn{
         width: 670px;
         height: 80px;
         display: block;
         margin:80px auto 0 auto;
         font-size:28px;
     }
 }