
.creating{
    .cover{
        width: 85%;
        min-height: 600px;
        margin:60px auto 0 auto;
        position:relative;
        .coverImg{
            width:100%;
            display: block;
        }
        .coverMark{
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color: rgba(0,0,0,0.5);
        }
        .coverState{
            position: absolute;
            left:50%;
            top:50%;
            width:200px;
            color:#dddddd;
            transform: translate(-50%,-50%);

            .coverIcon{
                width:100%;
            }
            .coverText{
                margin-top:40px;
                display: block;
                font-size: 26px;
            }
        }

    }
    
    .mainTitle{
        font-size: 55px;
        font-weight: bold;
        margin-top: 70px;
        color: #dddddd;
    }
    .subTitle{
        font-size: 32px;
        color: #999;
        margin-top: 30px;
    }
    .loading{
        background-color: #dddddd;
        width:80%;
        height:10px;
        margin:60px auto 0 auto;
        border-radius: 5px;
        overflow: hidden;

        .loadingCur{
            height:100%;
            background-color: #00C885;
        }
    }
    .percentage{
        color: #dddddd;
        margin-top:20px;
        font-size: 26px;
    }

    .text{
        margin-top:70px;
        color:#dddddd;
        font-size:26px;
    }
    .writeMusic{
        width: 400px;
        display: block;
        margin:80px auto 80px auto;
    }
}