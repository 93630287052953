 .container {
     padding: 0 40px 40px 40px;

     .title {
         font-size: 36px;
         color: #FFF8F8;
         font-weight: bold;
         text-align: center;
         margin-top: 10px;
     }

     .desc {
         font-size: 26px;
         color: #FFF8F8;
         opacity: 0.45;
         text-align: center;
         margin-top: 10px;
     }

     .step {
         width: 100%;
         display: flex;
         position: relative;
         margin-top: 30px;

         .stepLine {
             height: 4px;
             width: 33.3%;
             background-color: #00C885;
             opacity: 0.1;
         }

         .stepRound {
             position: absolute;
             top: -8px;
             width: 20px;
             height: 20px;
             background-color: #264F57;
             border-radius: 50%;
         }

         .round1 {
             left: 0px;
         }

         .round2 {
             left: 213px;
         }

         .round3 {
             left: 436px;
         }

         .round4 {
             right: 0px;
         }

         .deepColor {
             opacity: 1;
             background-color: #00C885;
         }
     }

     .main {
         display: flex;
         flex-wrap: wrap;
         align-content: space-between;
         justify-content: space-between;
         margin-top: 10px;
         // height: 900px;
         // overflow: auto;

         .item {
             width: 210px;
             //height:200px;
             margin-top: 20px;
             position: relative;
             background-color: #2a2a34;
             border-radius: 20px;
             overflow: hidden;
             border: 2px solid #181822;
             text-align: center;

             .itemIcon {
                 position: absolute;
                 top: 0px;
                 left: 0px;
                 width: 40px;
                 height: 40px;
             }

             image {
                 width: 200px;
                 height: 200px;
                 display: block;
             }

             .itemName {
                 text-align: center;
                 font-size: 24px;
                 color: #FFF8F8;
                 background-color: #35343e;
                 line-height: 45px;
                 opacity: 0.9;
             }
         }

         .selected {
             border: 2px solid #00C885;
         }
     }

     .exampleTitle {
         text-align: center;
         margin-top: 50px;
         margin-bottom: 50px;
         border-top: 1px solid rgba(255, 255, 255, 0.3);
         position: relative;

         .exampleText {
             width: 180px;
             color: rgba(255, 248, 248, 0.9);
             line-height: 40px;
             font-size: 28px;
             background-color: #181822;
             position: absolute;
             top: -20px;
             left: 50%;
             margin-left: -100px;

         }
     }

     .exampleList {
         display: flex;
         justify-content: space-between;

         .exampleItem {
             text-align: center;
             position: relative;
             border-radius: 20px;
             overflow: hidden;
             background-color: #2f2f39;


             .exampleImage {
                 width: 148px;
                 height: 188px;
                 display: block;
             }

             .exampleText {
                 font-size: 22px;
                 color: #FFF8F8;
                 line-height: 40px;
                 opacity: 0.45;
             }
         }
     }

     .Upload {
        margin-top: 40px;
        height: 575px;
    
        :global {

            .t-upload__card {
                padding: 0;
            }
    
            .t-upload__card-item {
                padding: 0;
                margin: 0;
                width: 100%;
                border-radius: 20px;
                overflow: hidden;
            }

    
            .t-upload__card-container,.t-upload__card-content{
                height: 575px;
                width: 100%;
                color: #666666;
                background-color: #3c3b4e;
    
                .t-icon-add {
                    color: #666666;
                    font-size: 40px;
                }
            }
        }
    
    
        .t-grid-item__content {
            height: 100%;
    
            .t-upload__wrapper {
                width: 100% !important;
                height: 100% !important;
            }
        }
    
        .t-icon-base {
            color: #666666;
        }
    }

     .popupBlock {
         width: 504px;
         height: 496px;
         background: linear-gradient(to bottom, #C3FFEB 5%, #ffffff 15%);
         border-radius: 16px;
         position: relative;
         padding: 40px;
         box-sizing: border-box;

         .popupImage {
             width: 140px;
             height: 140px;
             position: absolute;
             top: -70px;
             left: 50%;
             margin-left: -70px;
         }

         .popupTitle {
             text-align: center;
             font-size: 32px;
             color: #000000;
             margin-top: 50px;
         }

         .popupList {
             margin-top: 25px;
             display: flex;
             justify-content: space-around;

             .popupItem {
                 background-color: #ffffff;
                 border-radius: 10px;
                 margin-top: 10px;
                 color: #050505;
                 width: 180px;
                 height: 180px;
                 font-size: 24px;
                 text-align: center;
                 border: 1px solid #d9d9d9;
                 color: rgba(0, 0, 0, 0.45);
                 cursor: pointer;

                 .iconDownload {
                     width: 52px;
                     height: 52px;
                     margin-top: 40px;
                 }

                 .popupText {
                     margin-top: 8px;
                 }
             }

             .selected {
                 border: 1px solid #00c885;
                 background-color: #e9f8f3;
             }
         }

         .popupBtn {
             width: 100%;
             height: 72px;
             font-size: 28px;
             margin-top: 40px;
         }
     }

     .btn {
         width: 670px;
         height: 80px;
         display: block;
         margin: 80px auto 0 auto;
         font-size: 28px;
     }
 }