
.musicList{
  margin-top:100px;
  margin-bottom:50px;

  .musicTitle{
      color: #cccccc;
      margin-bottom: 30px;
      font-size: 28px;
  }
  .musicItem{
      background-color: #3c3b4e;
      padding: 15px 20px;
      margin-bottom: 15px;
      border-radius: 10px;
      color: #dddddd;
      font-size: 28px;   
      display: flex;
      align-items: center;
      justify-content: space-between; 
      cursor: pointer;

      .left{
          display: flex;
          align-items: center;
          img{
              width:100px;
              height:100px;
              border-radius: 8px;   
          }
          .name{
              margin-left:25px;    
              max-width: 330px;
              overflow:hidden;    //超出一行文字自动隐藏
              text-overflow:ellipsis;    //文字隐藏后添加省略号
              white-space:nowrap;    //强制不换行
          }
      }
      .right{
          color:#00C885;  
          img{
              width:25px;
              height:25px;
              position: relative;
              right:0px;
              top:3px;
              
          }
      }
      .noPass{
          color:#bbbbbb;    
      }
  }
  .musicText{
      font-size:26px;
      color:#bbbbbb;   
      text-align: center; 
      margin-top:20px;
  }
}