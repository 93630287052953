.containerLogin {
  box-sizing: border-box;
  height: 100vh;
  padding: 180px 80px 0 80px;

  display: grid;
  align-content: flex-start;
  align-items: center;
  justify-content: center;


  .title {
    font-size: 120px;
    font-weight: bold;
    color: #00C885;
    margin-top: 100px;
    text-align: center;
  }

  .subTitle {
    font-size: 50px;
    color: #dddddd;
    margin-top: 50px;
    text-align: center;
  }

  .actions {
    margin-top: 260px;
    display: grid;
    grid-auto-flow: row;
  }

  :global {
    .t-button {
      width: 500px;
      height: 88px !important;
      border-radius: 20px;
      margin: 150px auto 0 auto;
      padding: 20px;
      font-size: 28px !important;
      text-align: center;
      background-color: #00C885!important;
      font-weight: bold;
    }
  }

  .consent {
    color: #888888;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 24px;
    display:flex;

    input{
      margin-right: 20px;
      transform:scale(1.3);
    }
  }

  .consent a:link,
  .consent a:visited {
    color: #888888;
    text-decoration: none
  }

  .popupContent {
    background-color: #ffffff;
    padding: 1.5rem 1.5rem 3rem;
  }

}