.musicPlay {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
    padding:0 24px;
    justify-content: space-around;

    span{
        color: #999;
        font-size: 24px;
    }
}
.slider {
    width: 80%;
}